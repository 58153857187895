@use 'src/css/breakpoint.scss' as *;

.background {
  display: flex;
  align-items: center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include minMediaWidth(lg) {
    background-size: contain;
  }

  @include maxMediaWidth(lg) {
    background-size: auto 70%;
  }

  @include maxMediaWidth(sm) {
    background-size: auto 40%;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 240px 0 340px;
  text-align: center;
  position: relative;
  z-index: 1;

  .button {
    margin-top: 69px;
    padding: 8px 20px;

    @include maxMediaWidth(sm) {
      margin-top: 45px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    width: 446px;
    height: 446px;

    border-radius: 654px;
    background: var(--bg-error-page-gradient);
    filter: blur(100px);
    pointer-events: none;
    z-index: -1;
  }

  @include maxMediaWidth(lg) {
    padding: 150px 0 250px;
  }

  @include maxMediaWidth(sm) {
    justify-content: center;
    flex-wrap: wrap;
    padding: 150px 0 200px;
  }
}

.title {
  margin: 0 0 10px;
  font: var(--font-page-error);
  text-align: center;

  @include maxMediaWidth(sm) {
    font: var(--font-h1);
  }
}

.text {
  margin: 0;
  font: var(--font-h2);
  color: var(--secondary-text-color);
  text-transform: uppercase;

  @include maxMediaWidth(sm) {
    font: var(--font-h1-sm);
  }
}
